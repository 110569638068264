/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 575.98px) {
  .txn-id {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 120px;
  }
}

.parent-block-hash {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}

.spinner-border {
  animation-duration: 1s;
}

.blob-rusty {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 8px;
  width: 8px;
  transform: scale(1);
  background: $warning;
  box-shadow: 0 0 0 0 $warning;
  // animation: pulse-yellow 2s infinite;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 8px;
  width: 8px;
  transform: scale(1);
  background: $primary-active;
  box-shadow: 0 0 0 0 $primary-active;
  // animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $primary-active;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $warning;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.top-metrics-modal .ant-modal .ant-modal-content {
  padding: 0;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
  // background-color: aqua;
}

.adsense-banner {
  width: 320px;
  height: 100px;
}
@media (min-width: 500px) {
  .adsense-banner {
    width: 468px;
    height: 60px;
  }
}
@media (min-width: 800px) {
  .adsense-banner {
    width: 728px;
    height: 90px;
  }
}

.adsense-box {
  width: 300px;
  height: 250px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 8px;
  z-index: 2;
  color: grey;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 8px;
  z-index: 2;
  color: grey;
}
